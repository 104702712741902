export type StatsVehicleType = 'car' | 'motorcycle' | 'carros' | 'motos';

export default {
  stats: {
    home: `/ficha-tecnica`,

    brand({ brand, type }: { brand: string; type: StatsVehicleType }) {
      return `${this.type({ type })}/${encodeURIComponent(brand)}`;
    },
    model({
      brand,
      model,
      type,
    }: {
      brand: string;
      model: string;
      type: StatsVehicleType;
    }) {
      return `${this.brand({ brand, type })}/${encodeURIComponent(model)}`;
    },
    yearBikeStats({
      brand,
      model,
      type,
      year,
    }: {
      brand: string;
      model: string;
      type: StatsVehicleType;
      year: string | number;
    }) {
      return `${this.model({ brand, model, type })}/${encodeURIComponent(
        year,
      )}`;
    },

    type({ type }: { type: StatsVehicleType }) {
      const url: Record<typeof type, string> = {
        car: 'carros',
        motorcycle: 'motos',
        motos: 'motos',
        carros: 'carros',
      };
      return `${this.home}/${url[type]}`;
    },
  },
};
