import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const BounceAnimation = keyframes`
  0% { margin-bottom: 0; }
  50% { margin-bottom: 5px }
  100% { margin-bottom: 0 }
`;

interface DotProps {
  delay: string;
}

const Dot = styled.div<DotProps>`
  border-radius: 50%;
  margin: 0 5px;
  animation: ${BounceAnimation} 0.5s linear infinite;
  animation-delay: ${(props) => props.delay};
`;

const DotWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

interface LoadingDotsProps {
  color?: string;
  size?: number;
}

const LoadingDots: FC<LoadingDotsProps> = ({
  color = '#fff',
  size = 7,
}) => {
  const style = {
    width: size,
    height: size,
    backgroundColor: color,
    marginLeft: size - 2,
    marginRight: size - 2,
  };

  return (
    <DotWrapper>
      <Dot delay="0s" style={style} />
      <Dot delay=".1s" style={style} />
      <Dot delay=".2s" style={style} />
    </DotWrapper>
  );
};

export default LoadingDots;
