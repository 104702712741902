import { FipeVehicleType } from '@prisma/client';

export type FipeVehicleTypeUrl = 'carros' | 'motos' | 'caminhoes';

export default {
  fipe: {
    home: '/fipe',
    type({ type }: { type: FipeVehicleType | FipeVehicleTypeUrl }) {
      const url: Record<
        FipeVehicleType | FipeVehicleTypeUrl,
        FipeVehicleTypeUrl
      > = {
        car: 'carros',
        motorcycle: 'motos',
        trucks: 'caminhoes',
        caminhoes: 'caminhoes',
        motos: 'motos',
        carros: 'carros',
      };
      return `${this.home}/${url[type]}`;
    },
    brand({ brand, ...rest }: { type:  FipeVehicleType | FipeVehicleTypeUrl; brand: string }) {
      return `${this.type(rest)}/${encodeURIComponent(brand)}`;
    },
    model({
      model,
      ...rest
    }: {
      type:  FipeVehicleType | FipeVehicleTypeUrl;
      brand: string;
      model: string;
    }) {
      return `${this.brand(rest)}/${encodeURIComponent(model)}`;
    },
    modelYear({
      year,
      ...rest
    }: {
      year: number;
      type:  FipeVehicleType | FipeVehicleTypeUrl;
      brand: string;
      model: string;
    }) {
      return `${this.model(rest)}/${year}`;
    },
  },
};
