import { VehicleType } from '@lib/helpers/ipva';
import slugify from 'slugify';
import carrierPageRoutes from './routes/carrierPageRoutes';
import carrierRoutes from './routes/carrierRoutes';
import debtsRouter from './routes/debtsRouter';
import fipeRoutes from './routes/fipeRoutes';
import insurancePageRoutes from './routes/insurancePageRoutes';
import onboardingRoutes from './routes/onboardingRoutes';
import statsRoutes from './routes/statsRoutes';
import finaciamentoRoutes from './routes/finaciamentoRoutes';

const ROUTES = {
  root: '/',
  blog: '/blog',
  blogPost: (id: string) => `/blog/${id}`,
  blogCategory: (id: string) => `/blog/categorias/${id}`,

  signIn: '/entrar',
  signInRedirect(redirect: string) {
    return `${this.signIn}?redirect=${redirect}`;
  },
  account: '/minha-conta',
  privacy: '/privacidade',
  newPassword: '/esqueceu-a-senha',
  terms: '/termos',
  referral: (id: string) => `/r/${id}`,
  insuropedia: '/seguropedia',
  insuranceTerm(id: string) {
    return `${this.insuropedia}/${id}`;
  },
  downloadApp: '/baixe-o-app',
  stolenBikes: '/motos-visadas',
  brandStats(brand: string) {
    return `${this.stats.home}/${slugify(brand, { lower: true })}`;
  },
  modelStats(brand: string, model: string) {
    return `${this.brandStats(brand)}/${slugify(model, { lower: true })}`;
  },
  compare: '/comparativo',
  compareModels(modelA: string, modelB: string) {
    return `${this.compare}/${slugify(modelA, { lower: true })}/${slugify(
      modelB,
      { lower: true },
    )}`;
  },
  partnerships(id: string) {
    return `/parcerias/${id}`;
  },
  clubs: '/moto-clubes',
  stateClub(state: string) {
    return `${this.clubs}/${state.toLowerCase()}`;
  },
  club(state: string, name: string) {
    return `${this.stateClub(state)}/${slugify(name, { lower: true })}`;
  },

  ipva: '/ipva',
  ipvaYear({ year }: { year: string }) {
    return `${this.ipva}/${year}`;
  },
  ipvaState({ uf, ...rest }: { year: string; uf: string }) {
    return `${this.ipvaYear(rest)}/${uf}`;
  },
  ipvaType({ type, ...rest }: { year: string; uf: string; type: VehicleType }) {
    return `${this.ipvaState(rest)}/${type}`;
  },
  ipvaFromBrand({
    brand,
    ...rest
  }: {
    year: string;
    uf: string;
    brand: string;
    type: VehicleType;
  }) {
    return `${this.ipvaType(rest)}/${encodeURIComponent(brand)}`;
  },
  ipvaFromModel({
    model,
    ...rest
  }: {
    year: string;
    uf: string;
    brand: string;
    type: VehicleType;
    model: string;
  }) {
    return `${this.ipvaFromBrand(rest)}/${encodeURIComponent(model)}`;
  },
  ipvaDetail({
    modelYear,
    ...rest
  }: {
    year: string;
    uf: string;
    brand: string;
    type: VehicleType;
    model: string;
    modelYear: string;
  }) {
    return `${this.ipvaFromModel(rest)}/${modelYear}`;
  },

  ...carrierRoutes,
  ...onboardingRoutes,
  ...statsRoutes,
  ...insurancePageRoutes,
  ...carrierPageRoutes,
  ...fipeRoutes,
  ...debtsRouter,
  ...finaciamentoRoutes
};

export default ROUTES;
