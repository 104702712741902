
export default {
  carriers: {
    home: '/seguradoras',
    carrier(id: string) {
      return `${this.home}/${id}`;
    },
    comparative() {
      return `${this.home}/comparativo/`;
    },
    compare(carriers: { slug: string }[]) {
      const slugs = carriers.map((c) => c.slug);

      if (!slugs.length) return this.comparative();

      return (
        this.comparative() +
        (slugs.length === 1 ? slugs[0] : slugs.join('-vs-'))
      );
    },
  },
};
