import { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import COLORS from 'lib/constants/colors';
import { partnerConfig } from '@lib/helpers/partner';
import LoadingDots from '../LoadingDots';

export interface PrimaryButtonProps {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  style?: CSSProperties;
  type?: 'submit' | 'reset' | 'button';
  isBlock?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  pink?: boolean;
  id?: string;
  suppressHydrationWarning?: boolean;
  boxShadow?: boolean;
}

const StyledButton = styled.button<
  PrimaryButtonProps & { bgColor: string; fontColor?: string }
>`
  background-color: ${(props) =>
    props.disabled ? COLORS.lightGray : props.bgColor};
  font-size: 16px;
  color: ${(props) =>
    props.disabled ? COLORS.gray : props.fontColor || '#fff'};
  border-radius: ${partnerConfig?.config?.roundedButtons ? '50px' : '4px'};
  padding: 10px 22px;
  border: none;
  cursor: pointer;
  width: ${(props) => (props.isBlock ? '100%' : 'auto')};
  min-height: 39px;
  display: block;
  position: relative;
  transition: opacity 0.3s ease-in-out;
  /* font-family: 'Poppins', sans-serif; */
  text-decoration: none;
  ${(props) =>
    props.boxShadow
      ? `box-shadow: 0px 4px 50px 0px ${
          `${partnerConfig?.colors?.buttonColor}40` || '#FF008340'
        };`
      : ''}

  &:hover {
    opacity: 0.8;
  }

  :disabled {
    pointer-events: none;
    border: 0.5px solid ${COLORS.gray};
  }
`;

const PrimaryButton: FC<PrimaryButtonProps> = ({
  children,
  onClick,
  style,
  type,
  isBlock = false,
  isLoading = false,
  disabled = false,
  className,
  pink = false,
  id,
  suppressHydrationWarning,
  boxShadow,
}) => (
  <StyledButton
    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (onClick && !isLoading) onClick(e);
    }}
    className={className}
    type={type}
    style={style}
    isBlock={isBlock}
    disabled={disabled}
    bgColor={
      pink ? partnerConfig?.colors?.buttonColor || COLORS.pink : COLORS.black
    }
    fontColor={partnerConfig?.colors?.buttonFontColor}
    id={id}
    boxShadow={boxShadow}
    suppressHydrationWarning={suppressHydrationWarning}
  >
    {isLoading && (
      <div
        suppressHydrationWarning={suppressHydrationWarning}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <LoadingDots />
      </div>
    )}
    <div
      suppressHydrationWarning={suppressHydrationWarning}
      style={{
        visibility: isLoading ? 'hidden' : 'visible',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  </StyledButton>
);

export default PrimaryButton;
