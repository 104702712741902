import styled from 'styled-components';
import COLORS from 'lib/constants/colors';
import BREAKPOINTS from 'lib/constants/breakpoints';
import { partnerConfig } from '@lib/helpers/partner';

interface TextProps {
  center?: boolean;
  fontSize?: number;
  bold?: boolean;
}

const centerText = (props: TextProps) => props.center && 'text-align: center;';

const fontSize = (props: TextProps) =>
  props.fontSize && `font-size: ${props.fontSize}px;`;

const bold = (props: TextProps) => props.bold && 'font-weight: bold;';

const hFamily = `font-family: ${
  partnerConfig?.font?.heading.family || 'inherit'
};`;

export const Heading1 = styled.h1<TextProps>`
  font-size: 52px;
  font-weight: bold;
  font-style: normal;
  ${centerText}

  ${hFamily}

  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 36px;
  }
`;

export const Heading2 = styled.h2<TextProps>`
  font-size: 42px;
  font-weight: bold;
  font-style: normal;
  ${centerText}

  ${hFamily}


  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    font-size: 28px;
  }
`;

export const Heading3 = styled.h3<TextProps>`
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  ${centerText}

  ${hFamily}
`;

export const Heading4 = styled.h4<TextProps>`
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  ${centerText}

  ${hFamily}
`;

export const Paragraph = styled.p<TextProps>`
  font-weight: ${partnerConfig?.font?.body.weight || 100};
  ${centerText}
  ${fontSize}
  ${bold}
`;

export const PinkSpan = styled.span<TextProps>`
  color: ${COLORS.pink};
  ${centerText}
`;

export const LightParagraph = styled.p<TextProps>`
  font-weight: ${partnerConfig?.font?.body.weight || 100};
  color: ${COLORS.gray};
  ${centerText}
  ${fontSize}
`;
