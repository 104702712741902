import { FC } from 'react';

const UNIT = 8;

interface Props {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  vertical?: number;
  horizontal?: number;
  className?: string;
}

const generateSpace = (number?: number) => (number ? UNIT * number : undefined);

const Spacing: FC<Props> = ({
  children,
  top,
  left,
  right,
  bottom,
  vertical,
  horizontal,
  className,
}) => (
  <div
    className={className}
    style={{
      marginBottom: generateSpace(bottom || vertical),
      marginTop: generateSpace(top || vertical),
      marginLeft: generateSpace(left || horizontal),
      marginRight: generateSpace(right || horizontal),
    }}
  >
    {children}
  </div>
);

export default Spacing;
