import { CarrierId } from '@graphql/generated';
import { InsurancePageId } from '@lib/helpers/texts';
import slugify from 'slugify';

export default {
  insurancePage: {
    carrier(id: InsurancePageId, uid: CarrierId) {
      return `${this.home(id)}/seguradoras/${uid}`;
    },
    carriers(id: InsurancePageId) {
      return `${this.home(id)}/seguradoras`;
    },
    state(id: InsurancePageId, uf: string) {
      return `${this.states(id)}/${uf.toLowerCase()}`;
    },
    states(id: InsurancePageId) {
      return `${this.home(id)}/estados`;
    },
    home(id: InsurancePageId) {
      return `/${id}`;
    },
    brand(id: InsurancePageId, brand: string) {
      if (!brand) return '';

      return `${this.home(id)}/${slugify(brand, { lower: true })}`;
    },
    model(id: InsurancePageId, brand: string, model: string | number) {
      return `${this.brand(id, brand)}/${model}`;
    },
  },
};
