/* eslint-disable */
import { FipeModel, FipeValue } from '@prisma/client';
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type Address = {
  __typename?: 'Address';
  cep?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  cep?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
};

export enum AssistanceType {
  Guincho = 'guincho',
  Taxi = 'taxi',
  Borracheiro = 'borracheiro',
  Chaveiro = 'chaveiro',
  CarroReserva = 'carroReserva',
  Vidros = 'vidros',
}

export type BillingAddressInput = {
  zipCode: Scalars['String'];
  address: Scalars['String'];
  neighborhood: Scalars['String'];
  city: Scalars['String'];
  number: Scalars['String'];
};

export enum CallReason {
  TalkAboutQuote = 'talkAboutQuote',
  CarInsurance = 'carInsurance',
}

export type CarrierContactInfo = {
  __typename?: 'CarrierContactInfo';
  label: Scalars['String'];
  value: Scalars['String'];
  type: ContactType;
};

export type CarrierContacts = {
  __typename?: 'CarrierContacts';
  title: Scalars['String'];
  info: Array<CarrierContactInfo>;
};

export enum CarrierId {
  Suhai = 'suhai',
  Tokio = 'tokio',
  Mapfre = 'mapfre',
  Hdi = 'hdi',
  Porto = 'porto',
  Azul = 'azul',
  Allianz = 'allianz',
  Bradesco = 'bradesco',
  Ituran = 'ituran',
  Youse = 'youse',
  Sompo = 'sompo',
  Zurich = 'zurich',
  Justos = 'justos',
}

export type CarrierInfo = {
  __typename?: 'CarrierInfo';
  name: Scalars['String'];
  reclameAquiRate: Scalars['Float'];
  id: CarrierId;
  logoUrl: Scalars['String'];
  contacts: Array<CarrierContacts>;
};

export type Conductor = {
  __typename?: 'Conductor';
  name: Scalars['String'];
  birthday: Scalars['Int'];
  cpf: Scalars['String'];
  sex: Sex;
  maritalStatus?: Maybe<MaritalStatus>;
  id?: Maybe<Scalars['ID']>;
};

export type ConductorInput = {
  name: Scalars['String'];
  birthday: Scalars['Int'];
  cpf: Scalars['String'];
  sex: Sex;
  maritalStatus: MaritalStatus;
};

export enum ContactType {
  Tel = 'tel',
  Zap = 'zap',
  Email = 'email',
}

export type Coverage = {
  __typename?: 'Coverage';
  type: CoverageType;
  value?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description: Scalars['String'];
  label: Scalars['String'];
};

export enum CoverageType {
  Theft = 'theft',
  TotalLoss = 'totalLoss',
  ThirdPartyBody = 'thirdPartyBody',
  ThirdPartyMaterial = 'thirdPartyMaterial',
  MoralDamages = 'moralDamages',
  Comprehensive = 'comprehensive',
  AppDeath = 'appDeath',
  AppInvalid = 'appInvalid',
  Fire = 'fire',
  Colision = 'colision',
  Nature = 'nature',
}

export type CreateProposalInput = {
  address: AddressInput;
  installmentsNumber: Scalars['Int'];
  paymentMethod: PaymentMethod;
};

export type CreateQuoteInput = {
  vehicle: VehicleInput;
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  conductor: ConductorInput;
  mainConductor?: Maybe<ConductorInput>;
  referral?: Maybe<Scalars['String']>;
  youngCoverage: YoungConductorCoverage;
  userIntent: UserIntent;
  type: QuoteType;
  oldPolicy?: Maybe<OldPolicyInput>;
};

export type CreditCardInput = {
  document: Scalars['String'];
  number: Scalars['String'];
  brand: Scalars['String'];
  holder: Scalars['String'];
  expirationDate: Scalars['String'];
  cvv: Scalars['String'];
  billingAddress: BillingAddressInput;
};

export type DebtLabelValueAggregated = {
  __typename?: 'DebtLabelValueAggregated';
  type: DebtsType;
  total: Scalars['Int'];
};

export type DebtsInfo = {
  __typename?: 'DebtsInfo';
  debts: Array<VehicleDebts>;
  id: Scalars['ID'];
  debtsToPay: Array<Scalars['ID']>;
  total: Scalars['Int'];
  selectedDebts: Array<VehicleDebts>;
  installments: Array<VehicleDebtsInstallment>;
  vehicle: VehicleWithDebts;
  order?: Maybe<DebtsOrder>;
  paidDebts?: Maybe<Array<PaidDebts>>;
};

export type DebtsOrder = {
  __typename?: 'DebtsOrder';
  status: OrderStatus;
  info: TextInfo;
};

export enum DebtsType {
  Services = 'services',
  Service = 'service',
  Ticket = 'ticket',
  RenainfTicket = 'renainf_ticket',
  Ipva = 'ipva',
  Licensing = 'licensing',
  IpvaUnique = 'ipva_unique',
  IpvaInstallment = 'ipva_installment',
  Insurance = 'insurance',
  ExpiredInsurance = 'expired_insurance',
  Dpvat = 'dpvat',
}

export enum Fuel {
  Gas = 'gas',
  Flex = 'flex',
  Alcohol = 'alcohol',
  Hibrid = 'hibrid',
  Eletric = 'eletric',
  Gnv = 'gnv',
  Diesel = 'diesel',
}

export enum HouseType {
  Apartment = 'apartment',
  House = 'house',
  Condo = 'condo',
  Farm = 'farm',
  Other = 'other',
}

export type Indication = {
  __typename?: 'Indication';
  id: Scalars['ID'];
  status: IndicationStatus;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  reward?: Maybe<ReferralReward>;
};

export enum IndicationStatus {
  RealizedQuote = 'realizedQuote',
  WaitingPolicy = 'waitingPolicy',
  Finished = 'finished',
}

export type Installment = {
  __typename?: 'Installment';
  hasFee: Scalars['Boolean'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
  number: Scalars['Int'];
  method: PaymentMethod;
};

export type Lead = {
  __typename?: 'Lead';
  email: Scalars['String'];
  origin: Scalars['String'];
};

export enum MaritalStatus {
  Single = 'single',
  Married = 'married',
  Widower = 'widower',
  Divorced = 'divorced',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** @deprecated  */
  _empty?: Maybe<Scalars['String']>;
  personalizeQuote: Quote;
  createQuote: Quote;
  resetQuoteOfAnotherVehicle: Quote;
  createProposal: Quote;
  createLead: Lead;
  addViewer: Viewer;
  saveViewerVehicle: UserVehicle;
  removeViewerVehicle: UserVehicle;
  searchVehicleDebts: VehicleDebtsSearch;
  selectDebtsToPay: DebtsInfo;
  payDebts: VehicleDebtsResult;
};

export type MutationPersonalizeQuoteArgs = {
  id: Scalars['ID'];
  input: PersonalizeQuoteInput;
};

export type MutationCreateQuoteArgs = {
  input: CreateQuoteInput;
};

export type MutationResetQuoteOfAnotherVehicleArgs = {
  id: Scalars['ID'];
  licencePlate: Scalars['String'];
};

export type MutationCreateProposalArgs = {
  id: Scalars['ID'];
  offerId: Scalars['String'];
  input: CreateProposalInput;
};

export type MutationCreateLeadArgs = {
  email: Scalars['String'];
  origin: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
};

export type MutationAddViewerArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
};

export type MutationSaveViewerVehicleArgs = {
  vehicle: ViewerVehicleInput;
};

export type MutationRemoveViewerVehicleArgs = {
  id: Scalars['ID'];
};

export type MutationScheduleACallArgs = {
  data: Scalars['JSON'];
  reason: CallReason;
};

export type MutationSearchVehicleDebtsArgs = {
  plate: Scalars['String'];
  email: Scalars['String'];
  document?: Maybe<Scalars['String']>;
};

export type MutationSelectDebtsToPayArgs = {
  protocol: Scalars['String'];
  debts: Array<Scalars['ID']>;
};

export type MutationPayDebtsArgs = {
  protocol: Scalars['ID'];
  card: CreditCardInput;
  installmentPlan: Scalars['Int'];
};

export type Offer = {
  __typename?: 'Offer';
  id: Scalars['String'];
  coverages: Array<Coverage>;
  price: OfferPrice;
  type: OfferType;
  carrier: CarrierInfo;
  assistances: Array<OfferAssistance>;
  fullCoverage: Scalars['Boolean'];
  fipeFactor: Scalars['Int'];
  deductible?: Maybe<Scalars['Int']>;
};

export type OfferAssistance = {
  __typename?: 'OfferAssistance';
  type: AssistanceType;
  name: Scalars['String'];
  label: Scalars['String'];
  description: Scalars['String'];
};

export enum OfferId {
  SuhaiRoubo = 'suhaiRoubo',
  SuhaiPt = 'suhaiPT',
  SuhaiCompleto = 'suhaiCompleto',
  SuhaiRouboTerceiros = 'suhaiRouboTerceiros',
  TokioAuto = 'tokioAuto',
  TokioClassico = 'tokioClassico',
  TokioRoubo = 'tokioRoubo',
  TokioPopular = 'tokioPopular',
  TokioMoto = 'tokioMoto',
  TokioRouboRastreador = 'tokioRouboRastreador',
  TokioMotoSugestao = 'tokioMotoSugestao',
  MapfreAuto = 'mapfreAuto',
  HdiAuto = 'hdiAuto',
  BradescoClassic = 'bradescoClassic',
  BradescoLight = 'bradescoLight',
  BradescoTradicional = 'bradescoTradicional',
  BradescoTradicionalPlus = 'bradescoTradicionalPlus',
  BradescoPrime = 'bradescoPrime',
  BradescoExclusive = 'bradescoExclusive',
  IturanCompleto = 'ituranCompleto',
  IturanRoubo = 'ituranRoubo',
  IturanCombo = 'ituranCombo',
  IturanPt = 'ituranPT',
  AllianzEssencial = 'allianzEssencial',
  AllianzBasico = 'allianzBasico',
  AllianzCompleto = 'allianzCompleto',
  AllianzMaster = 'allianzMaster',
  AllianzExclusivo = 'allianzExclusivo',
  AllianzAmpliado = 'allianzAmpliado',
  YouseBasico = 'youseBasico',
  YouseMedio = 'youseMedio',
  YouseCompleto = 'youseCompleto',
}

export type OfferPrice = {
  __typename?: 'OfferPrice';
  total: Scalars['Int'];
  installments: Array<Installment>;
  bestInstallment: Installment;
  cheaperInstallment: Installment;
  paymentMethods: Array<PaymentMethod>;
};

export enum OfferType {
  Basic = 'basic',
  Medium = 'medium',
  Complete = 'complete',
}

export type OldPolicyInput = {
  carrier: Scalars['String'];
  bonus: Scalars['Int'];
  id?: Scalars['ID'];
};

export enum OrderStatus {
  PaymentInitiated = 'PAYMENT_INITIATED',
  CheckoutSuccess = 'CHECKOUT_SUCCESS',
  CheckoutFail = 'CHECKOUT_FAIL',
  Search = 'SEARCH',
  Simulation = 'SIMULATION',
  ServiceUnavailable = 'SERVICE_UNAVAILABLE',
  BarcodeEmitted = 'BARCODE_EMITTED',
  VehicleWithoutDebts = 'VEHICLE_WITHOUT_DEBTS',
  VehicleNotFound = 'VEHICLE_NOT_FOUND',
}

export type PaidDebts = {
  __typename?: 'PaidDebts';
  id: Scalars['ID'];
  amount: Scalars['Int'];
  title: Scalars['String'];
  type: DebtsType;
  description: Scalars['String'];
};

export enum Parking {
  ManualGate = 'manualGate',
  AutomaticGate = 'automaticGate',
  PaidParking = 'paidParking',
  Outside = 'outside',
}

export enum PaymentMethod {
  Boleto = 'boleto',
  CreditCard = 'creditCard',
  Debit = 'debit',
}

export enum PersonalizationFilter {
  Required = 'required',
  Cool = 'cool',
  Nop = 'nop',
}

export type PersonalizeQuoteInput = {
  fipeFactor: PersonalizationFilter;
  colision: PersonalizationFilter;
  thirdParty: PersonalizationFilter;
};

export type Policy = {
  __typename?: 'Policy';
  id: Scalars['ID'];
  endDate: Scalars['Int'];
  carrier: CarrierInfo;
};

export type Proposal = {
  __typename?: 'Proposal';
  status: ProposalStatusType;
  carrier: CarrierInfo;
  pdfUrl?: string;
};

export enum ProposalStatusType {
  FinishedQuote = 'finishedQuote',
  DataSent = 'dataSent',
  UnderAnalysis = 'underAnalysis',
  WaitingPolicy = 'waitingPolicy',
  Issued = 'issued',
  Refused = 'refused',
}

export type Query = {
  __typename?: 'Query';
  /**
   * query vazia como um workaround na escalabilidade de cada endpoint definir seu proprio type e extender a RootQuery
   * @deprecated
   */
  _empty?: Maybe<Scalars['String']>;
  hello?: Maybe<Scalars['JSON']>;
  vehicles: Array<VehicleVersion>;
  vehicleValue: FipeValue;
  vehiclesByPlate: Array<VehicleVersion>;
  availableVehicleYears: Array<Scalars['Int']>;
  quote: Quote;
  viewer: Viewer;
  person?: Maybe<Conductor>;
  vehicleDebts: DebtsInfo;
  recentVehicleDebt?: Maybe<DebtsInfo>;
  vehicleDebtsResume: VehicleDebtsResume;
};

export type QueryVehiclesArgs = {
  year: Scalars['Int'];
  type: VehicleType;
};

export type QueryVehiclesByPlateArgs = {
  plate: Scalars['String'];
};

export type QueryQuoteArgs = {
  id: Scalars['ID'];
};

export type QueryPersonArgs = {
  cpf: Scalars['String'];
};

export type QueryVehicleDebtsArgs = {
  protocol: Scalars['String'];
};

export type QueryRecentVehicleDebtArgs = {
  email: Scalars['String'];
  plate: Scalars['String'];
};

export type QueryVehicleDebtsResumeArgs = {
  protocol: Scalars['String'];
};

export type Quote = {
  __typename?: 'Quote';
  id: Scalars['ID'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  created: Scalars['Int'];
  vehicle: VehicleVersion;
  address?: Maybe<Address>;
  availableVehicleYears: Array<Scalars['Int']>;
  conductor: Conductor;
  mainConductor?: Maybe<Conductor>;
  installmentsNumber?: Maybe<Scalars['Int']>;
  proposal?: Maybe<Proposal>;
  offer: Offer;
  offers: Array<Offer>;
  personalizedOffers: Array<Offer>;
  calculationDate: Scalars['Int'];
  done: Scalars['Boolean'];
  userIntent: UserIntent;
};

export type QuoteOfferArgs = {
  id?: Maybe<Scalars['String']>;
};

export enum QuoteType {
  Renovation = 'renovation',
  New = 'new',
}

export type ReferralReward = {
  __typename?: 'ReferralReward';
  paid: Scalars['Boolean'];
  value: Scalars['Int'];
};

export enum SalaryRangesEnum {
  Band1 = 'BAND1',
  Band2 = 'BAND2',
  Band3 = 'BAND3',
  Band4 = 'BAND4',
  Minimum = 'MINIMUM',
}

export enum Sex {
  Male = 'male',
  Female = 'female',
}

export type TextInfo = {
  __typename?: 'TextInfo';
  title: Scalars['String'];
  description: Scalars['String'];
};

export enum UserIntent {
  Renovation = 'renovation',
  Expired = 'expired',
  NeedInsuranceNow = 'needInsuranceNow',
  DrivingWithoutInsurance = 'drivingWithoutInsurance',
  BuyingVehicleSoon = 'buyingVehicleSoon',
  Curious = 'curious',
  InternalRenovation = 'internalRenovation',
  SuhaiLead = 'suhaiLead',
  SuhaiPartner = 'suhaiPartner',
  Partner = 'partner',
}

export type UserVehicle = {
  __typename?: 'UserVehicle';
  modelYear: Scalars['Int'];
  brand: Scalars['String'];
  model: Scalars['String'];
  manufacturingYear?: Maybe<Scalars['Int']>;
  fipe: Scalars['String'];
  chassi: Scalars['String'];
  type: VehicleType;
  plate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  policy?: Maybe<Policy>;
  quote?: Maybe<Quote>;
  ipva?: Maybe<VehicleIpva>;
};

export type VehicleDebts = {
  __typename?: 'VehicleDebts';
  amount: Scalars['Float'];
  title: Scalars['String'];
  description: Scalars['String'];
  due_date?: Maybe<Scalars['String']>;
  expiration_date: Scalars['String'];
  fine: Scalars['String'];
  has_discount: Scalars['Boolean'];
  is_expired: Scalars['Boolean'];
  id: Scalars['ID'];
  interest: Scalars['String'];
  type: DebtsType;
  year: Scalars['String'];
  ipva: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  depends_on?: Maybe<Array<Scalars['String']>>;
  distinct?: Maybe<Array<Scalars['String']>>;
};

export type VehicleDebtsInstallment = {
  __typename?: 'VehicleDebtsInstallment';
  number: Scalars['Int'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
};

export type VehicleDebtsResult = {
  __typename?: 'VehicleDebtsResult';
  success: Scalars['Boolean'];
};

export type VehicleDebtsResume = {
  __typename?: 'VehicleDebtsResume';
  id: Scalars['ID'];
  values: Array<DebtLabelValueAggregated>;
};

export type VehicleDebtsSearch = {
  __typename?: 'VehicleDebtsSearch';
  debts: Array<VehicleDebts>;
  id: Scalars['ID'];
};

export type VehicleIpva = {
  __typename?: 'VehicleIPVA';
  year: Scalars['Int'];
  uf: Scalars['String'];
  marketValue: Scalars['Int'];
  value: Scalars['Int'];
};

export type VehicleInput = {
  modelYear: Scalars['Int'];
  fipe: Scalars['String'];
  manufacturingYear: Scalars['Int'];
  utilization: Array<VehicleUtilization>;
  houseType: HouseType;
  schoolParking?: Maybe<Parking>;
  workParking?: Maybe<Parking>;
  houseParking: Parking;
  zeroKm?: Maybe<Scalars['Boolean']>;
  overnightAddress: AddressInput;
  type: VehicleType;
  chassi?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  model: Scalars['String'];
};

export enum VehicleType {
  Car = 'car',
  Motorcycle = 'motorcycle',
  Trucks = 'trucks',
  Other = 'other',
}

export enum VehicleUtilization {
  Leisure = 'leisure',
  Delivery = 'delivery',
  Freight = 'freight',
  Sport = 'sport',
  App = 'app',
  Work = 'work',
  Commuting = 'commuting',
  School = 'school',
  Taxi = 'taxi',
}

export type VehicleVersion = {
  __typename?: 'VehicleVersion';
  modelYear: Scalars['Int'];
  brand: Scalars['String'];
  model: Scalars['String'];
  fipe: Scalars['String'];
  manufacturingYear?: Maybe<Scalars['Int']>;
  chassi?: Maybe<Scalars['String']>;
  fuel?: Maybe<Fuel>;
  color?: Maybe<Scalars['String']>;
  houseParking?: Maybe<Parking>;
  schoolParking?: Maybe<Parking>;
  workParking?: Maybe<Parking>;
  zeroKm?: Maybe<Scalars['Boolean']>;
  overnightAddress?: Maybe<Address>;
  type: VehicleType;
  plate?: Maybe<Scalars['String']>;
  houseType?: Maybe<HouseType>;
};

export type VehicleWithDebts = {
  __typename?: 'VehicleWithDebts';
  license_plate: Scalars['String'];
};

export type Viewer = {
  __typename?: 'Viewer';
  quotes: Array<Quote>;
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  referralId: Scalars['String'];
  indications: Array<Indication>;
  vehicles: Array<UserVehicle>;
  vehicle: UserVehicle;
  proposals: Array<Quote>;
  pendingDebts: Array<DebtsInfo>;
};

export type ViewerVehicleArgs = {
  id: Scalars['ID'];
};

export type ViewerVehicleInput = {
  modelYear: Scalars['Int'];
  brand: Scalars['String'];
  model: Scalars['String'];
  fipe: Scalars['String'];
  chassi: Scalars['String'];
  type: VehicleType;
  plate: Scalars['String'];
  manufacturingYear: Scalars['Int'];
};

export enum YoungConductorCoverage {
  Nop = 'nop',
  Male = 'male',
  Female = 'female',
  Both = 'both',
  DontDrive = 'dontDrive',
}

export type SearchVehicleDebtsMutationVariables = Exact<{
  email: Scalars['String'];
  plate: Scalars['String'];
  document?: Maybe<Scalars['String']>;
}>;

export type SearchVehicleDebtsMutation = { __typename?: 'Mutation' } & {
  searchVehicleDebts: { __typename?: 'VehicleDebtsSearch' } & Pick<
    VehicleDebtsSearch,
    'id'
  >;
};

export type SelectDebtsToPayMutationVariables = Exact<{
  protocol: Scalars['String'];
  debts: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type SelectDebtsToPayMutation = { __typename?: 'Mutation' } & {
  selectDebtsToPay: { __typename?: 'DebtsInfo' } & Pick<DebtsInfo, 'id'>;
};

export type DebtsResumeQueryVariables = Exact<{
  protocol: Scalars['String'];
}>;

export type DebtsResumeQuery = { __typename?: 'Query' } & {
  vehicleDebtsResume: { __typename?: 'VehicleDebtsResume' } & Pick<
    VehicleDebtsResume,
    'id'
  > & {
      values: Array<
        { __typename?: 'DebtLabelValueAggregated' } & Pick<
          DebtLabelValueAggregated,
          'type' | 'total'
        >
      >;
    };
};

export type PayDebtsMutationVariables = Exact<{
  protocol: Scalars['ID'];
  card: CreditCardInput;
  installmentPlan: Scalars['Int'];
}>;

export type PayDebtsMutation = { __typename?: 'Mutation' } & {
  payDebts: { __typename?: 'VehicleDebtsResult' } & Pick<
    VehicleDebtsResult,
    'success'
  >;
};

export type GetDebtsToPayQueryVariables = Exact<{
  protocol: Scalars['String'];
}>;

export type GetDebtsToPayQuery = { __typename?: 'Query' } & {
  vehicleDebts: { __typename?: 'DebtsInfo' } & Pick<
    DebtsInfo,
    'id' | 'total'
  > & {
      installments: Array<
        { __typename?: 'VehicleDebtsInstallment' } & Pick<
          VehicleDebtsInstallment,
          'amount' | 'total' | 'number'
        >
      >;
      selectedDebts: Array<
        { __typename?: 'VehicleDebts' } & Pick<
          VehicleDebts,
          'title' | 'id' | 'amount' | 'description'
        >
      >;
    };
};

export type VehicleDebtsQueryVariables = Exact<{
  protocol: Scalars['String'];
}>;

export type VehicleDebtsQuery = { __typename?: 'Query' } & {
  vehicleDebts: { __typename?: 'DebtsInfo' } & Pick<DebtsInfo, 'id'> & {
      order?: Maybe<{ __typename?: 'DebtsOrder' } & Pick<DebtsOrder, 'status'>>;
      debts: Array<
        { __typename?: 'VehicleDebts' } & Pick<
          VehicleDebts,
          | 'title'
          | 'id'
          | 'amount'
          | 'description'
          | 'required'
          | 'depends_on'
          | 'distinct'
        >
      >;
      selectedDebts: Array<
        { __typename?: 'VehicleDebts' } & Pick<VehicleDebts, 'id'>
      >;
      vehicle: { __typename?: 'VehicleWithDebts' } & Pick<
        VehicleWithDebts,
        'license_plate'
      >;
      paidDebts?: Maybe<
        Array<
          { __typename?: 'PaidDebts' } & Pick<
            PaidDebts,
            'id' | 'amount' | 'title' | 'type' | 'description'
          >
        >
      >;
    };
};

export type GetVehiclesByYearQueryVariables = Exact<{
  year: Scalars['Int'];
  type: VehicleType;
}>;

export type GetVehiclesByYearQuery = { __typename?: 'Query' } & {
  vehicles: Array<
    { __typename?: 'VehicleVersion' } & Pick<
      VehicleVersion,
      'modelYear' | 'model' | 'brand' | 'fipe'
    >
  >;
};

export type AvailableVehicleYearsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AvailableVehicleYearsQuery = { __typename?: 'Query' } & Pick<
  Query,
  'availableVehicleYears'
>;

export type GetVehiclesByPlateQueryVariables = Exact<{
  plate: Scalars['String'];
}>;

export type GetVehiclesByPlateQuery = { __typename?: 'Query' } & {
  vehiclesByPlate: Array<
    { __typename?: 'VehicleVersion' } & Pick<
      VehicleVersion,
      | 'type'
      | 'manufacturingYear'
      | 'modelYear'
      | 'fipe'
      | 'brand'
      | 'model'
      | 'chassi'
      | 'plate'
    >
  >;
};

export type ViewerIndicationsQueryVariables = Exact<{ [key: string]: never }>;

export type ViewerIndicationsQuery = { __typename?: 'Query' } & {
  viewer: { __typename?: 'Viewer' } & Pick<Viewer, 'id' | 'referralId'> & {
      indications: Array<
        { __typename?: 'Indication' } & Pick<
          Indication,
          'id' | 'status' | 'phoneNumber' | 'name'
        > & {
            reward?: Maybe<
              { __typename?: 'ReferralReward' } & Pick<
                ReferralReward,
                'paid' | 'value'
              >
            >;
          }
      >;
    };
};

export type PrefillQuoteWithViewerDataQueryVariables = Exact<{
  vehicleId: Scalars['ID'];
}>;

export type PrefillQuoteWithViewerDataQuery = { __typename?: 'Query' } & {
  viewer: { __typename?: 'Viewer' } & Pick<
    Viewer,
    'id' | 'phoneNumber' | 'email'
  > & {
      vehicle: { __typename?: 'UserVehicle' } & Pick<
        UserVehicle,
        | 'id'
        | 'model'
        | 'brand'
        | 'modelYear'
        | 'type'
        | 'plate'
        | 'fipe'
        | 'manufacturingYear'
      >;
    };
};

export type ViewerQueryVariables = Exact<{ [key: string]: never }>;

export type ViewerQuery = { __typename?: 'Query' } & {
  viewer: { __typename?: 'Viewer' } & Pick<Viewer, 'id' | 'name'> & {
      proposals: Array<
        { __typename?: 'Quote' } & Pick<
          Quote,
          'id' | 'email' | 'userIntent'
        > & {
            vehicle: { __typename?: 'VehicleVersion' } & Pick<
              VehicleVersion,
              | 'type'
              | 'manufacturingYear'
              | 'modelYear'
              | 'fipe'
              | 'brand'
              | 'model'
              | 'chassi'
            >;
            proposal?: Maybe<
              { __typename?: 'Proposal' } & Pick<Proposal, 'status' | 'pdfUrl'>
            >;
          }
      >;
    };
};

export type CreateQuoteMutationVariables = Exact<{
  input: CreateQuoteInput;
}>;

export type CreateQuoteMutation = { __typename?: 'Mutation' } & {
  createQuote: { __typename?: 'Quote' } & Pick<Quote, 'id'>;
};

export type ResetQuoteOfAnotherVehicleMutationVariables = Exact<{
  id: Scalars['ID'];
  licencePlate: Scalars['String'];
}>;

export type ResetQuoteOfAnotherVehicleMutation = { __typename?: 'Mutation' } & {
  resetQuoteOfAnotherVehicle: { __typename?: 'Quote' } & Pick<Quote, 'id'>;
};

export type CreateProposalMutationVariables = Exact<{
  id: Scalars['ID'];
  offerId: Scalars['String'];
  input: CreateProposalInput;
}>;

export type CreateProposalMutation = { __typename?: 'Mutation' } & {
  createProposal: { __typename?: 'Quote' } & Pick<Quote, 'id'>;
};

export type CreateLeadMutationVariables = Exact<{
  email: Scalars['String'];
  origin: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
}>;

export type CreateLeadMutation = { __typename?: 'Mutation' } & {
  createLead: { __typename?: 'Lead' } & Pick<Lead, 'email'>;
};

export type QuoteOffersFieldFragment = { __typename?: 'Offer' } & Pick<
  Offer,
  'type' | 'id' | 'fullCoverage' | 'fipeFactor' | 'deductible'
> & {
    coverages: Array<
      { __typename?: 'Coverage' } & Pick<Coverage, 'type' | 'value'>
    >;
    carrier: { __typename?: 'CarrierInfo' } & CarrierInfoFieldFragment;
    price: { __typename?: 'OfferPrice' } & Pick<
      OfferPrice,
      'total' | 'installments'
    > & {
        bestInstallment: { __typename?: 'Installment' } & Pick<
          Installment,
          'amount' | 'number'
        >;
        cheaperInstallment: { __typename?: 'Installment' } & Pick<
          Installment,
          'amount' | 'number'
        >;
      };
    assistances: Array<
      { __typename?: 'OfferAssistance' } & Pick<
        OfferAssistance,
        'label' | 'type'
      >
    >;
  };

export type QuoteOffersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type QuoteOffersQuery = { __typename?: 'Query' } & {
  quote: { __typename?: 'Quote' } & Pick<
    Quote,
    'id' | 'phoneNumber' | 'email' | 'calculationDate' | 'userIntent'
  > & {
      conductor: { __typename?: 'Conductor' } & Pick<Conductor, 'name'>;
      offers: Array<{ __typename?: 'Offer' } & QuoteOffersFieldFragment>;
      vehicle: Pick<VehicleVersion, 'model' | 'modelYear' | 'brand'>;
    };
};

export type CarrierInfoFieldFragment = { __typename?: 'CarrierInfo' } & Pick<
  CarrierInfo,
  'name' | 'id' | 'reclameAquiRate' | 'logoUrl'
>;

export type QuoteOfferQueryVariables = Exact<{
  quoteId: Scalars['ID'];
  offerId: Scalars['String'];
}>;

export type QuoteOfferQuery = { __typename?: 'Query' } & {
  quote: { __typename?: 'Quote' } & Pick<
    Quote,
    'id' | 'phoneNumber' | 'userIntent'
  > & {
      proposal?: Maybe<{ __typename?: 'Proposal' } & Pick<Proposal, 'status'>>;
      conductor: { __typename?: 'Conductor' } & Pick<
        Conductor,
        'name' | 'maritalStatus' | 'birthday'
      >;
      mainConductor?: Maybe<
        { __typename?: 'Conductor' } & Pick<
          Conductor,
          'name' | 'maritalStatus' | 'birthday'
        >
      >;
      vehicle: { __typename?: 'VehicleVersion' } & Pick<
        VehicleVersion,
        'brand' | 'model' | 'plate' | 'type' | 'chassi' | 'modelYear'
      > & {
          overnightAddress?: Maybe<
            { __typename?: 'Address' } & Pick<
              Address,
              'street' | 'cep' | 'neighborhood' | 'city' | 'state'
            >
          >;
        };
      offer: { __typename?: 'Offer' } & Pick<
        Offer,
        'type' | 'id' | 'fipeFactor' | 'fullCoverage' | 'deductible'
      > & {
          carrier: { __typename?: 'CarrierInfo' } & CarrierInfoFieldFragment;
          assistances: Array<
            { __typename?: 'OfferAssistance' } & Pick<
              OfferAssistance,
              'name' | 'label' | 'description' | 'type'
            >
          >;
          coverages: Array<
            { __typename?: 'Coverage' } & Pick<
              Coverage,
              'value' | 'type' | 'name' | 'label' | 'description'
            >
          >;
          price: { __typename?: 'OfferPrice' } & Pick<OfferPrice, 'total'> & {
              bestInstallment: { __typename?: 'Installment' } & Pick<
                Installment,
                'amount' | 'number' | 'method'
              >;
              cheaperInstallment: { __typename?: 'Installment' } & Pick<
                Installment,
                'amount' | 'number' | 'method'
              >;
              installments: Array<
                { __typename?: 'Installment' } & Pick<
                  Installment,
                  'amount' | 'number' | 'hasFee' | 'method'
                >
              >;
            };
        };
    };
};

export type PersonQueryVariables = Exact<{
  cpf: Scalars['String'];
}>;

export type PersonQuery = { __typename?: 'Query' } & {
  person?: Maybe<
    { __typename?: 'Conductor' } & Pick<
      Conductor,
      'name' | 'sex' | 'cpf' | 'birthday' | 'id'
    >
  >;
};

export type PersonalizeQuoteMutationVariables = Exact<{
  id: Scalars['ID'];
  input: PersonalizeQuoteInput;
}>;

export type PersonalizeQuoteMutation = { __typename?: 'Mutation' } & {
  personalizeQuote: { __typename?: 'Quote' } & Pick<Quote, 'id'>;
};

export type WakeUpLambdaQueryVariables = Exact<{ [key: string]: never }>;

export type WakeUpLambdaQuery = { __typename?: 'Query' } & Pick<Query, 'hello'>;

export const CarrierInfoFieldFragmentDoc = gql`
  fragment CarrierInfoField on CarrierInfo {
    name
    id
    reclameAquiRate
    logoUrl
  }
`;
export const QuoteOffersFieldFragmentDoc = gql`
  fragment QuoteOffersField on Offer {
    type
    id
    fullCoverage
    fipeFactor
    deductible
    coverages {
      type
      value
    }
    carrier {
      ...CarrierInfoField
    }
    price {
      total
      bestInstallment {
        amount
        number
      }
      cheaperInstallment {
        amount
        number
      }
      installments {
        amount
        number
        method
      }
    }
    assistances {
      label
      type
    }
  }
  ${CarrierInfoFieldFragmentDoc}
`;
export const SearchVehicleDebtsDocument = gql`
  mutation searchVehicleDebts(
    $email: String!
    $plate: String!
    $document: String
  ) {
    searchVehicleDebts(email: $email, plate: $plate, document: $document) {
      id
    }
  }
`;

export function useSearchVehicleDebtsMutation() {
  return Urql.useMutation<
    SearchVehicleDebtsMutation,
    SearchVehicleDebtsMutationVariables
  >(SearchVehicleDebtsDocument);
}
export const SelectDebtsToPayDocument = gql`
  mutation selectDebtsToPay($protocol: String!, $debts: [ID!]!) {
    selectDebtsToPay(protocol: $protocol, debts: $debts) {
      id
    }
  }
`;

export function useSelectDebtsToPayMutation() {
  return Urql.useMutation<
    SelectDebtsToPayMutation,
    SelectDebtsToPayMutationVariables
  >(SelectDebtsToPayDocument);
}
export const DebtsResumeDocument = gql`
  query debtsResume($protocol: String!) {
    vehicleDebtsResume(protocol: $protocol) {
      id
      values {
        type
        total
      }
    }
  }
`;

export function useDebtsResumeQuery(
  options: Omit<Urql.UseQueryArgs<DebtsResumeQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<DebtsResumeQuery>({
    query: DebtsResumeDocument,
    ...options,
  });
}
export const PayDebtsDocument = gql`
  mutation payDebts(
    $protocol: ID!
    $card: CreditCardInput!
    $installmentPlan: Int!
  ) {
    payDebts(
      protocol: $protocol
      card: $card
      installmentPlan: $installmentPlan
    ) {
      success
    }
  }
`;

export function usePayDebtsMutation() {
  return Urql.useMutation<PayDebtsMutation, PayDebtsMutationVariables>(
    PayDebtsDocument,
  );
}
export const GetDebtsToPayDocument = gql`
  query getDebtsToPay($protocol: String!) {
    vehicleDebts(protocol: $protocol) {
      id
      total
      installments {
        amount
        total
        number
      }
      selectedDebts {
        title
        id
        amount
        description
      }
    }
  }
`;

export function useGetDebtsToPayQuery(
  options: Omit<Urql.UseQueryArgs<GetDebtsToPayQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<GetDebtsToPayQuery>({
    query: GetDebtsToPayDocument,
    ...options,
  });
}
export const VehicleDebtsDocument = gql`
  query vehicleDebts($protocol: String!) {
    vehicleDebts(protocol: $protocol) {
      id
      order {
        status
      }
      debts {
        title
        id
        amount
        description
        required
        depends_on
        distinct
      }
      selectedDebts {
        id
      }
      vehicle {
        license_plate
      }
      paidDebts {
        id
        amount
        title
        type
        description
      }
    }
  }
`;

export function useVehicleDebtsQuery(
  options: Omit<Urql.UseQueryArgs<VehicleDebtsQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<VehicleDebtsQuery>({
    query: VehicleDebtsDocument,
    ...options,
  });
}
export const GetVehiclesByYearDocument = gql`
  query getVehiclesByYear($year: Int!, $type: VehicleType!) {
    vehicles(year: $year, type: $type) {
      modelYear
      model
      brand
      fipe
    }
  }
`;

export function useGetVehiclesByYearQuery(
  options: Omit<
    Urql.UseQueryArgs<GetVehiclesByYearQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<GetVehiclesByYearQuery>({
    query: GetVehiclesByYearDocument,
    ...options,
  });
}
export const AvailableVehicleYearsDocument = gql`
  query availableVehicleYears {
    availableVehicleYears
  }
`;

export function useAvailableVehicleYearsQuery(
  options: Omit<
    Urql.UseQueryArgs<AvailableVehicleYearsQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<AvailableVehicleYearsQuery>({
    query: AvailableVehicleYearsDocument,
    ...options,
  });
}

export function useVehicleValueQuery(
  options: Omit<
    Urql.UseQueryArgs<
      Exact<{
        fipeCode: Scalars['String'];
        year: Scalars['Int'];
      }>
    >,
    'query'
  > = {},
) {
  return Urql.useQuery<{ __typename?: 'Query' } & Pick<Query, 'vehicleValue'>>({
    query: gql`
      query vehicleValue($year: Int!, $fipeCode: String!) {
        vehicleValue(year: $year, fipeCode: $fipeCode) {
          value
          fipeMonth
          fipeYear
        }
      }
    `,
    ...options,
  });
}

export const GetVehiclesByPlateDocument = gql`
  query getVehiclesByPlate($plate: String!) {
    vehiclesByPlate(plate: $plate) {
      type
      manufacturingYear
      modelYear
      fipe
      brand
      model
      chassi
      plate
    }
  }
`;

export function useGetVehiclesByPlateQuery(
  options: Omit<
    Urql.UseQueryArgs<GetVehiclesByPlateQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<GetVehiclesByPlateQuery>({
    query: GetVehiclesByPlateDocument,
    ...options,
  });
}
export const ViewerIndicationsDocument = gql`
  query viewerIndications {
    viewer {
      id
      referralId
      indications {
        id
        status
        phoneNumber
        name
        reward {
          paid
          value
        }
      }
    }
  }
`;

export function useViewerIndicationsQuery(
  options: Omit<
    Urql.UseQueryArgs<ViewerIndicationsQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<ViewerIndicationsQuery>({
    query: ViewerIndicationsDocument,
    ...options,
  });
}
export const PrefillQuoteWithViewerDataDocument = gql`
  query prefillQuoteWithViewerData($vehicleId: ID!) {
    viewer {
      id
      phoneNumber
      email
      vehicle(id: $vehicleId) {
        id
        model
        brand
        modelYear
        type
        plate
        fipe
        manufacturingYear
      }
    }
  }
`;

export function usePrefillQuoteWithViewerDataQuery(
  options: Omit<
    Urql.UseQueryArgs<PrefillQuoteWithViewerDataQueryVariables>,
    'query'
  > = {},
) {
  return Urql.useQuery<PrefillQuoteWithViewerDataQuery>({
    query: PrefillQuoteWithViewerDataDocument,
    ...options,
  });
}
export const ViewerDocument = gql`
  query viewer {
    viewer {
      id
      name
      proposals {
        id
        email
        userIntent
        vehicle {
          type
          manufacturingYear
          modelYear
          fipe
          brand
          model
          chassi
        }
        proposal {
          status
          pdfUrl
        }
      }
    }
  }
`;

export function useViewerQuery(
  options: Omit<Urql.UseQueryArgs<ViewerQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<ViewerQuery>({ query: ViewerDocument, ...options });
}
export const CreateQuoteDocument = gql`
  mutation createQuote($input: CreateQuoteInput!) {
    createQuote(input: $input) {
      id
    }
  }
`;

export function useCreateQuoteMutation() {
  return Urql.useMutation<CreateQuoteMutation, CreateQuoteMutationVariables>(
    CreateQuoteDocument,
  );
}
export const ResetQuoteOfAnotherVehicleDocument = gql`
  mutation resetQuoteOfAnotherVehicle($id: ID!, $licencePlate: String!) {
    resetQuoteOfAnotherVehicle(id: $id, licencePlate: $licencePlate) {
      id
    }
  }
`;

export function useResetQuoteOfAnotherVehicleMutation() {
  return Urql.useMutation<
    ResetQuoteOfAnotherVehicleMutation,
    ResetQuoteOfAnotherVehicleMutationVariables
  >(ResetQuoteOfAnotherVehicleDocument);
}
export const CreateProposalDocument = gql`
  mutation createProposal(
    $id: ID!
    $offerId: String!
    $input: CreateProposalInput!
  ) {
    createProposal(id: $id, offerId: $offerId, input: $input) {
      id
    }
  }
`;

export function useCreateProposalMutation() {
  return Urql.useMutation<
    CreateProposalMutation,
    CreateProposalMutationVariables
  >(CreateProposalDocument);
}
export const CreateLeadDocument = gql`
  mutation createLead($email: String!, $origin: String!, $metadata: JSON) {
    createLead(email: $email, origin: $origin, metadata: $metadata) {
      email
    }
  }
`;

export function useCreateLeadMutation() {
  return Urql.useMutation<CreateLeadMutation, CreateLeadMutationVariables>(
    CreateLeadDocument,
  );
}

export const QuoteOffersDocument = gql`
  query quoteOffers($id: ID!) {
    quote(id: $id) {
      id
      userId
      phoneNumber
      email
      userIntent
      calculationDate
      conductor {
        name
      }
      offers {
        ...QuoteOffersField
      }
      vehicle {
        brand
        model
        modelYear
      }
    }
  }
  ${QuoteOffersFieldFragmentDoc}
`;

export function useQuoteOffersQuery(
  options: Omit<Urql.UseQueryArgs<QuoteOffersQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<QuoteOffersQuery>({
    query: QuoteOffersDocument,
    ...options,
  });
}
export const QuoteOfferDocument = gql`
  query quoteOffer($quoteId: ID!, $offerId: String!) {
    quote(id: $quoteId) {
      id
      phoneNumber
      userId
      userIntent
      proposal {
        status
      }
      conductor {
        name
        maritalStatus
        birthday
      }
      mainConductor {
        name
        maritalStatus
        birthday
      }
      vehicle {
        brand
        model
        plate
        type
        chassi
        modelYear
        overnightAddress {
          street
          cep
          neighborhood
          city
          state
        }
      }
      offer(id: $offerId) {
        type
        id
        fipeFactor
        carrier {
          ...CarrierInfoField
        }
        assistances {
          name
          label
          description
          type
        }
        fullCoverage
        deductible
        coverages {
          value
          type
          name
          label
          description
        }
        price {
          total
          bestInstallment {
            amount
            number
            method
          }
          cheaperInstallment {
            amount
            number
            method
          }
          installments {
            amount
            number
            hasFee
            method
          }
        }
      }
    }
  }
  ${CarrierInfoFieldFragmentDoc}
`;

export function useQuoteOfferQuery(
  options: Omit<Urql.UseQueryArgs<QuoteOfferQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<QuoteOfferQuery>({
    query: QuoteOfferDocument,
    ...options,
  });
}
export const PersonDocument = gql`
  query person($cpf: String!) {
    person(cpf: $cpf) {
      name
      sex
      cpf
      birthday
      id
    }
  }
`;

export function usePersonQuery(
  options: Omit<Urql.UseQueryArgs<PersonQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<PersonQuery>({ query: PersonDocument, ...options });
}
export const PersonalizeQuoteDocument = gql`
  mutation personalizeQuote($id: ID!, $input: PersonalizeQuoteInput!) {
    personalizeQuote(id: $id, input: $input) {
      id
    }
  }
`;

export function usePersonalizeQuoteMutation() {
  return Urql.useMutation<
    PersonalizeQuoteMutation,
    PersonalizeQuoteMutationVariables
  >(PersonalizeQuoteDocument);
}
export const WakeUpLambdaDocument = gql`
  query wakeUpLambda {
    hello
  }
`;

export function useWakeUpLambdaQuery(
  options: Omit<Urql.UseQueryArgs<WakeUpLambdaQueryVariables>, 'query'> = {},
) {
  return Urql.useQuery<WakeUpLambdaQuery>({
    query: WakeUpLambdaDocument,
    ...options,
  });
}
